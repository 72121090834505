import { v5 as uuid } from 'uuid'
import { v4 as uuidv4 } from 'uuid'
import ls from 'localstorage-slim'
import APP_ROUTES from '../Constants/APP_ROUTES'
import AppStore from '../Configurations/AppStore'
import { watchlistObj } from '../Redux/WatchList/Reducer'
import TickerStore from '../Configurations/TickerStore'
import { STATUS_FILTER_MAPPER } from '../Pages/Orderbook/Orderbook.constants'
import { FormValues } from '../Components/OrderModal/Components/OrderFormSection'
import { parseISO, format, isSameDay, parse } from 'date-fns'
import Bowser from 'bowser'
import { NRI_TYPES } from '../Constants/APP_CONSTANTS'
import { TDeepLink } from '../Redux/AppState/reducer'
import { S3_ASSET_URL, USE_CLEVERTAP } from '~/src/Configurations/env'

ls.config.encrypt = true

export const FULL_SCREEN = [
  APP_ROUTES.FUNDSDASHBOARD.pathname,
  APP_ROUTES.MOBILE_EDIT_WATCHLIST.pathname,
  APP_ROUTES?.MOBILE_SEARCH?.pathname,
  APP_ROUTES?.LIMIT_STATEMENT?.pathname,
  APP_ROUTES?.QUOTES?.pathname,
  APP_ROUTES?.DASHBOARD_NEWS?.pathname,
  '/limit-statement/EQ',
  '/limit-statement/COMM',
  '/research-category',
  APP_ROUTES?.SCREENERS?.pathname,
  APP_ROUTES?.PROFILE?.pathname,
  APP_ROUTES?.BANKDETAILS?.pathname,
  APP_ROUTES?.EXCHANGEMESSAGES?.pathname,
  APP_ROUTES?.MARKETSTATUS?.pathname,
  APP_ROUTES?.POSITION_SQ_OFF?.pathname,
  APP_ROUTES?.PLEDGE?.pathname
]

export const DIRECT_TO_DASHBOARD_ROUTES = [
  APP_ROUTES.MOBILE_WATCHLIST.pathname,
  APP_ROUTES.MOBILE_EDIT_WATCHLIST.pathname,
  APP_ROUTES?.MOBILE_SEARCH?.pathname
]

export const isAuthenticated = () => true

export const UUIDGenerator = (name: string) => {
  const nameSpace = '57e9fe74-3d60-11ed-aa61-1359468a0d29'
  return uuid(name, nameSpace)
}

export const UUIDGenerator_V4 = () => {
  return uuidv4()
}

export const setLocalStorage = (key: string, value: any) => {
  ls.set(key, value, { secret: 12 })
}

export const getLocalStorage = (key: string) => {
  return ls.get(key, { secret: 12 })
}

export const removeLocalStorage = (key: string) => {
  ls.remove(key)
}

const hiddenEmail = (emailAddress: string, emailAddressType: string) => {
  return (
    emailAddress?.charAt(0) +
    '*****' +
    emailAddress?.charAt(emailAddress.length - 1) +
    '@' +
    emailAddressType
  )
}

const hiddenMobileEmail = (
  mobileNumber: string,
  emailAddress: string,
  emailAddressType: string,
  pageState?: { subHeading: string }
) => {
  if (pageState !== undefined) {
    if (mobileNumber !== undefined)
      return (
        pageState.subHeading +
        ' ' +
        '+91 ' +
        (mobileNumber.slice(-4).padStart(10, '*') +
          ' ' +
          'and' +
          ' ' +
          hiddenEmail(emailAddress, emailAddressType))
      )
    else {
      return (
        pageState.subHeading + ' ' + hiddenEmail(emailAddress, emailAddressType)
      )
    }
  } else {
    return (
      hiddenEmail(emailAddress, emailAddressType) +
      ' ' +
      'and' +
      ' ' +
      '+91 ' +
      mobileNumber.slice(-4).padStart(10, '*')
    )
  }
}

export const hiddenText = (
  mobileNumber: string,
  email: string,
  pageState?: { subHeading: string }
) => {
  const emailAddress = email?.split('@')[0]
  const emailAddressType = email?.split('@')[1]
  let data = hiddenMobileEmail(
    mobileNumber,
    emailAddress,
    emailAddressType,
    pageState
  )

  /*
  for NRI customers we need to show only the email id as OTP is not sent on the mobile numbr in NRI cust case
   */

  return {
    ...pageState,
    subHeading: data
  }
}

export const handleClickShowPassword = (show: boolean) => !show

export const handleMouseDownPassword = (
  event: React.MouseEvent<HTMLButtonElement>
) => {
  return event.preventDefault()
}

export const getIntervalInMileSeconds = (mileSeconds: any, callbackFn: any) => {
  return setInterval(() => {
    callbackFn()
  }, mileSeconds)
}

export const convertToMinuteAndSeconds = function (seconds: any) {
  let minutes = Math.floor(seconds / 60)
  let extraSeconds = seconds % 60
  minutes = minutes < 10 ? '0' + minutes : (minutes as any)
  extraSeconds = extraSeconds < 10 ? '0' + extraSeconds : (extraSeconds as any)
  return minutes + ':' + extraSeconds
}

export const getPrimaryAccount = (
  accounts: { isPrimary: boolean; subAccountId: string }[]
) => {
  // get primary subaccount id
  const primaryAccount = accounts.find(({ isPrimary }) => isPrimary === true)
  // const subAccountId = primaryAccount?.subAccountId || accounts[0].subAccountId
  // const primaryAccount = accounts[0]

  return primaryAccount
}

export const getSubAccountIdFromRedux = () => {
  const store = AppStore.getState()
  const { subAccountId } = store.sso
  return subAccountId
}

export const getRequiredDataForHeaders = () => {
  return {
    'X-SubAccountID': getSubAccountIdFromRedux(),
    'X-SourceChannel': 'galaxy',
    'x-ipaddress': '192.168.65.1'
  }
}

export const calculateNetChange = (
  LTP: number,
  closeprice: number,
  segment?: string
) => {
  const netChange = LTP - closeprice
  const percentChange = closeprice ? (netChange / closeprice) * 100 : 0
  const netChangeInString = `${netChange >= 0 ? '+' : ''}${netChange.toFixed(
    segment === 'CURR' ? 4 : 2
  )}`
  const percentChangeInString = `${
    netChange >= 0 ? '+' : ''
  }${percentChange.toFixed(2)}`
  return [netChangeInString, percentChangeInString]
}

function getTimeDiffString(seconds: number): string {
  if (seconds <= 60) {
    return '1 min ago'
  } else if (seconds <= 3600000) {
    return `${Math.trunc(seconds / 60000)} min ago`
  } else if (seconds <= 86400000) {
    return `${Math.trunc(seconds / 3600000)} hr ago`
  } else {
    return `${Math.trunc(seconds / 86400000)} days ago`
  }
}

export const getTimeDifference = (
  timeStr: string,
  timeFormat: string = ''
): string => {
  const timeStruct =
    timeFormat !== ''
      ? parse(timeStr, timeFormat, new Date())
      : new Date(timeStr)
  const localTime = new Date()
  const currentDate = new Date()

  const timeDiff = localTime.getTime() - timeStruct.getTime()

  if (isSameDay(timeStruct, currentDate)) {
    return getTimeDiffString(timeDiff)
  } else {
    return convertDatestamp(timeStruct.toISOString())
  }
}

export const isCustomerHaveCommodity = () => {
  const store = AppStore.getState()
  const { customer } = store
  const { customerProfile = {} } = customer
  const { segmentsEnabled = {} } = customerProfile
  const { mcxCmx = '', ncdexCmx = '' } = segmentsEnabled

  return mcxCmx || ncdexCmx
}

export const isPledgeActivated = () => {
  const store = AppStore.getState()
  const { customer } = store
  const { customerProfile = {} } = customer
  const { productsAllowed = {} } = customerProfile
  const { pledgeMargin = {} } = productsAllowed

  return (
    pledgeMargin?.isActive ||
    pledgeMargin?.metadata?.code != 'ERR_OMS_UNIMPLEMENTED'
  )
}

export const getCurrencyFormat = (
  number: { toString: () => string },
  options = {},
  withoutCurrencyFormat?: boolean
) => {
  if (withoutCurrencyFormat)
    return (number && number.toString().replace(/,/g, '')) || 0
  const defaultOptions = { style: 'currency', currency: 'INR' }
  const finalOptions = { ...defaultOptions, ...options }
  if (number === 0) {
    return Intl.NumberFormat('en-IN', finalOptions).format(Number(0))
  }
  if (number) {
    const onlyNumbers = number.toString().replace(/,/g, '')
    return Intl.NumberFormat('en-IN', finalOptions).format(Number(onlyNumbers))
  }
  return ''
}

export const getMaskedString = (inputStr: string | number, type: string) => {
  let str: string = inputStr.toString()
  if (type === 'email') {
    const emailParts = str.split('@')
    const maskedString =
      emailParts[0].slice(0, 3) +
      emailParts[0].slice(3).replace(/./g, '*') +
      '@' +
      emailParts[1]
    return maskedString
  }
  if (type === 'number') {
    const maskedString = str.slice(0, -4).replace(/./g, '*') + str.slice(-4)
    return maskedString
  }
  if (type === 'pan') {
    const maskedString = str.replace(/\d/g, '*')
    return maskedString
  }
}

export const areEqual = (arr1: any[], arr2: any[]) => {
  let N = arr1.length
  let M = arr2.length

  // If lengths of array are not equal means
  // array are not equal
  if (N != M) return false

  // Sort both arrays
  let a1 = [...arr1].sort()
  let a2 = [...arr2].sort()

  // Linearly compare elements
  for (let i = 0; i < N; i++) if (a1[i] != a2[i]) return false

  // If all elements were same.
  return true
}

export const formTabsArray = (watchListData: watchlistObj[]) => {
  const tabsArray = watchListData.map((watchList: watchlistObj) => {
    return {
      label: watchList.watchlistName,
      id: (watchList.watchlistSequenceNumber - 1).toString()
    }
  })
  return tabsArray
}

export const capitalizeFirstLetter = (dataString: string) => {
  if (dataString) {
    return (
      dataString.charAt(0).toUpperCase() + dataString.slice(1).toLowerCase()
    )
  }

  return ''
}

export const handleTruncatedHeadingValue = (str: string) => {
  return str?.length > 40 ? str.substring(0, 35) + '...' : str
}

export const handleTruncatedCaptionValue = (str: string) => {
  return str?.length > 100 ? str.substring(0, 95) + '...' : str
}

export const getFormattedDate = (dateString: string) => {
  const [date, time] = dateString.split(' ')
  const formattedTime =
    (time && (time as any)?.match(/.{1,2}/g).join(':')) || '00:00'
  return `${date} ${formattedTime}`
}

export const getPrimaryDpAccount = (dpAccounts: any[]) => {
  const primaryDpAccount = dpAccounts?.find(
    ({ dpDefault }) => dpDefault === true
  )
  return primaryDpAccount
}

export const tickerValue = (scriptId: string) => {
  const store = TickerStore.getState()
  const { ticker } = store
  const scriptTickerData = ticker[scriptId]
  const { LTP } = scriptTickerData || {}
  return LTP
}

export const getScriptPriceRange = (scriptId: string) => {
  const store = TickerStore.getState()
  const { ticker } = store
  const scriptTickerData = ticker[scriptId]
  const { DAILY_PRICE_RANGE = '' } = scriptTickerData || {}
  const priceRange = DAILY_PRICE_RANGE?.split('-')
  return priceRange
}

export const getScriptName = (scriptId: string) => {
  const store = AppStore.getState()
  const { stockMaster } = store
  const scriptTickerData = stockMaster[scriptId]
  const { exchangeSymbol } = scriptTickerData || {}
  return exchangeSymbol
}

export const getScriptExchange = (scriptId: string) => {
  const store = AppStore.getState()
  const { stockMaster } = store
  const scriptTickerData = stockMaster[scriptId]
  const { exchange } = scriptTickerData || {}
  return exchange
}

export const isStockPresentInAnyWatchlist = (scriptId: string) => {
  const store = AppStore.getState()
  let isPresent = false
  const { stockWatchlistMaster } = store
  const { data } = stockWatchlistMaster
  if (data[scriptId]) {
    isPresent = data[scriptId].length ? true : false
  }
  return isPresent
}

export const getFilterData = (data: any, filters: any) => {
  let filteredArray = data
  for (const key in filters) {
    const value = filters[key]
    filteredArray = filteredArray?.filter((item: any) => {
      if (typeof value !== 'string') {
        if (key === 'exchange') {
          const exchange = item[key].split('_')[0]
          return value.length === 0 || value.includes(exchange)
        } else return value.length === 0 || value.includes(item[key])
      } else {
        return (
          STATUS_FILTER_MAPPER[item[key]] === value ||
          value === 'All' ||
          item[key] === value
        )
      }
    })
  }
  return filteredArray
}

export const getScreenersFilterData = (data: any, filters: any) => {
  let filteredArray = data

  for (const key in filters) {
    const value = filters[key]
    filteredArray = filteredArray.filter((item: any) => {
      if (
        value &&
        (item[key] === value ||
          value == 'ALL' ||
          value.toUpperCase() === 'ALL EXPIRY')
      )
        return item
    })
  }
  return filteredArray
}

export const getProductType = (values: FormValues) => {
  const { sellableQtyType, encash, orderType } = values
  const isSellQtyTypeDEMAT = sellableQtyType === 'DEMAT'
  const isSellQtyTypeCollatoral = sellableQtyType === 'Collateral (Pledged)'
  const isSellQtyTypeBmpl = sellableQtyType === 'BMPL'

  let productType = ''

  if (orderType === 'INTRADAY') {
    productType = 'INTRADAY'
  } else if (isSellQtyTypeDEMAT && encash) {
    productType = 'EN_CASH'
  } else if (isSellQtyTypeDEMAT && !encash) {
    productType = 'DELIVERY'
  } else if (isSellQtyTypeCollatoral) {
    productType = 'COLLSELL'
  } else if (isSellQtyTypeBmpl) {
    productType = 'MTF'
  }

  return productType
}

export function formatIndianCurrency(
  number: number,
  options: { suffixFormat?: 'short' | 'long' } = {}
): string {
  const { suffixFormat = 'short' } = options

  const suffixes: { [key: string]: string[] } = {
    short: ['', 'k', 'l', 'cr'],
    long: ['', ' Thousand', ' Lakh', ' Crore']
  }

  let magnitudeIndex: number = 0
  let divisor: number = 1

  if (number >= 10000000) {
    magnitudeIndex = 3 // Crore
    divisor = 10000000
  } else if (number >= 100000) {
    magnitudeIndex = 2 // Lakh
    divisor = 100000
  } else if (number >= 1000) {
    magnitudeIndex = 1 // Thousand
    divisor = 1000
  }

  const suffix: string[] = suffixes[suffixFormat]

  // Calculate formatted number
  const formattedNumber: string = (number / divisor).toFixed(2)
  return `${formattedNumber} ${suffix[magnitudeIndex]}`
}

export const convertTimestamp = (timestamp: string) => {
  if (!timestamp) return
  const parsedDate = parseISO(timestamp)
  // const formattedDate = format(parsedDate, 'MMMM d, yyyy, HH:mm:ss')
  const formattedDate = format(
    parsedDate, //this
    'do MMM, yyyy, hh:mm:ss a'
  )
  return formattedDate
}
export const convertDatestamp = (timestamp: string) => {
  if (!timestamp) return
  const parsedDate = parseISO(timestamp)
  // const formattedDate = format(parsedDate, 'MMMM d, yyyy, HH:mm:ss')
  const formattedDate = format(
    parsedDate, //this
    'do MMM, yyyy'
  )
  return formattedDate
}

// example of how to use the function

//  const customAttributes = {
//    "Event Status": 'Pass',
//    "Age": '23',
//    "Gender": "Male"
//  }
//  triggerCleverTapEvent('Event_Name', customAttributes)
export const triggerCleverTapEvent = (
  eventName: string,
  customAttributes = {}
) => {
  const { deviceName, osType } = AppStore.getState().indicators
  const systemAttributes = {
    'App Name': 'Galaxy',
    'App Version': '1.0.13',
    'Device Type': 'Web',
    'Name of Device':
      deviceName ||
      Bowser.parse(window.navigator.userAgent).browser.name +
        ' ' +
        Bowser.parse(window.navigator.userAgent).browser.version ||
      'Not Fetched',
    'OS Type':
      osType ||
      Bowser.parse(window.navigator.userAgent).os.name +
        ' ' +
        Bowser.parse(window.navigator.userAgent).os.version ||
      'Not Fetched',
    'Event Time': new Date().toISOString()
  }

  const eventData = { ...systemAttributes, ...customAttributes }

  if (USE_CLEVERTAP === 'true')
    window.clevertap.event.push(eventName, eventData)
}

export const handleCustomerNameCasing = (name: string) => {
  if (name) {
    return name
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }
}

export const getDeepLinkData = (pathNameArray: string[]) => {
  const [_, deeplinkEnum, parameter1, parameter2] = pathNameArray
  const deepLinkData: TDeepLink = {
    path: deeplinkEnum,
    parameterList: [parameter1, parameter2]
  }

  return deepLinkData
}

export function getScripImageUrl(CoCode: string | number) {
  const state = AppStore.getState()
  const { theme = {} } = state
  const { scheme = 'light' } = theme
  return [
    {
      src: new URL(`${S3_ASSET_URL}/${scheme}/${CoCode}.png`, import.meta.url)
        .href,
      alt: 'logo',
      as: 'image/png'
    }
  ]
}

export const getNRICustomer = (accounts: any[]): boolean => {
  const nriTypeValues = new Set(Object.values(NRI_TYPES))
  return accounts.some(account => nriTypeValues.has(account.settlementType))
}

export interface IUrl {
  light: string
  dark: string
}
export function getImageUrlOfApi(url: IUrl) {
  const state = AppStore.getState()
  const { theme = {} } = state
  const { scheme = 'light' } = theme

  const srcUrl = url[scheme as keyof IUrl]

  return [
    {
      src: new URL(srcUrl, import.meta.url).href,
      alt: 'logo',
      as: 'image/png'
    }
  ]
}

export const getTypeOfOrder = (
  triggerPrice: number,
  isMarketDepthMarket: boolean
) => {
  if (triggerPrice && isMarketDepthMarket) {
    return 'SL-MKT'
  }
  if (triggerPrice && !isMarketDepthMarket) {
    return 'SL'
  }
  if (!triggerPrice && isMarketDepthMarket) {
    return 'RL-MKT'
  }
  return 'RL'
}
