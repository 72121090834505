import React, { Component } from 'react'
import { Outlet, To } from 'react-router-dom'
import { ThunkDispatch } from '@reduxjs/toolkit'
import {
  DsBox,
  DsPopup,
  DsStack,
  enqueueNotistack,
  withBreakpoints
} from '@am92/react-design-system'

// components
import Header from '../Components/Header/Header'
import Sidebar from '../Components/Sidebar/Sidebar'
import BottomNavigation from '../Components/BottomNavigation/BottomNavigation'
import MobileHeader from '../Components/MobileHeader/MobileHeader'
import OrderModal from '../Components/OrderModal/OrderModal'
import PostLoginErrors from '../Pages/Quotes/Components/PostLoginErrors'
import SearchStocks from 'src/Components/Sidebar/Components/Search'

// lib
import { IWithRouterProps } from '../Lib/withRouter'
import withErrorConnect from '../Lib/withErrorConnect'

// selectors
import { getOrderPageSipModalIndicator } from '../Redux/Indicators/Selectors'
import { getShowPostLoginError } from '../Redux/Orders/selectors'

// actions
import {
  setPortfolioIndicator,
  setPortfolioTabIndicator,
  setTabIndicator
} from '~/src/Redux/Indicators/Reducer'
import getAvailableEquityLimitsAction from '../Redux/Inquiry/Services/getAvailableEquityLimits'
import getAvailableCommodityLimitsAction from '../Redux/Inquiry/Services/getAvailableCommodityLimits'
import getCustomerProfileDetailsAction from '../Redux/Customer/Services/getCustomerProfileDetails'

// utils
import { FULL_SCREEN } from '../Utils/global'
import RiskDisclosure from '../Components/RiskDisclosure'
import { isNSEFOEnabled } from '../Redux/Customer/Selectors'
import { getDeviceActiveScreen } from '../Utils/deviceDetails'
import {
  getCommodityValueSelector,
  getEquityValueSelector
} from '../Redux/Inquiry/Selectors'

import {
  getSSOAuthenticateData,
  getSubAccountIdSelector
} from '../Redux/SSO/Selectors'
import { DEEP_LINK_KEYS } from '../Constants/APP_CONSTANTS'
import { getDeepLinkAttributes } from '../Redux/AppState/selectors'
import { TDeepLink, resetDeepLinkData } from '../Redux/AppState/reducer'
import { T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD } from '../Pages/Orderbook/Orderbook.Page'
import { updateOrderPageModalIndicator } from '../Redux/Orders/reducer'
import APP_ROUTES from '../Constants/APP_ROUTES'
import AlertsFormModal from '../Components/AlertsForm/AlertsFormModal'
import { getAlertsFormDetails } from '../Redux/Alerts/Selectors'
import SipModal from '../Components/SipModal/SipModal'
import getNotificationsAction from '../Redux/Notifications/Services/getNotifications.Service'
import ReverseFeedSocket from '../Components/ReverseFeedAlert/ReverseFeedSocket'
import { getReverseFeedAlertData } from '../Redux/ReverseFeed/Selectors'
import { USE_CLEVERTAP } from '~/src/Configurations/env'
import { IAuthenticationResponse } from '../Redux/SSO/Reducer'
import getHoldingsSummaryAction, {
  getHoldingsSummaryPayload
} from '../Redux/Dashboard/Services/getHoldingsSummary.Service'
declare module 'notistack' {
  interface VariantOverrides {
    custom: true
  }
}

type ActionTypes = {
  getCustomerProfileDetails: () => any
  setTabIndicator: (reqData: number) => any
  getAvailableEquityLimits: (data: any) => any
  getAvailableCommodityLimits: (data: any) => any
  resetDeepLinkData: () => Promise<any>
  updateOrderPageModalIndicator: (
    reqData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
  ) => Promise<any>
  getNotifications: (data: any) => any
  setPortfolioTabIndicator: (reqData: number) => Promise<any>
  setPortfolioIndicator: (reqData: number) => Promise<any>
  getHoldingsSummary: (requestData: getHoldingsSummaryPayload) => Promise<any>
}
export interface IMainLayoutProps extends IWithRouterProps {
  breakpoints: any
  actions: ActionTypes
  searchParams: any
  handleError: (res: any) => void
  isShowPostLoginErrorTrue: boolean
  isNSEFOEnabledSelector: any
  getCommodityValueSelect: any
  getEquityValueSelect: any
  getSubAccountId: string
  deepLinkAttributes: TDeepLink
  alertFormDetails: any
  authenticateSSOSelector: IAuthenticationResponse
  orderPageSipModalIndicator: boolean
  reverseFeedAlertData: {
    alertTime: string | number
    alertColor: string
    alertIcon: string
    alertIconColor: 'success' | 'error'
    alertHeading: string
    alertDescription: string
    alertNavigationButtonTypo: string
    alertNavigationPath: string
  }
}

const APP_PAGES_FOR_MOBILE_LAYOUT = [
  '/quotes',
  '/profile',
  '/partners',
  '/reports',
  '/recommendation',
  '/notifications',
  '/alerts',
  '/portfolio/mutualfunds',
  '/portfolio/smartedgeplus',
  '/portfolio/stocks',
  '/portfolio/cat3',
  '/portfolio-mutualfund',
  '/research-category',
  '/alerts'
]
class MainLayout extends Component<IMainLayoutProps> {
  state = {
    showSearch: false,
    open: false
  }

  async componentDidMount() {
    const {
      getCommodityValueSelect,
      getEquityValueSelect,
      getSubAccountId,
      actions
    } = this.props

    await actions.getNotifications({})
    // deeplink handling for orderform
    this.handleDeepLink()

    if (!getEquityValueSelect) {
      this.handleGetEquityLimits()
    }
    if (!getCommodityValueSelect) {
      this.handleGetCommodityLimits()
    }

    this.handleGetCustomerProfileDetails()
    this.handleGetHoldingsSummaryDetails()

    // NOTE: commented code - as we have to disable it on prod
    // if (getSubAccountId) {
    //   new ReverseFeedSocket(
    //     `${getSubAccountId}|eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3NDUzMjg2MjV9.tUQ4xS2vhv71-sA0pxCZTvj8MZFmVIz4fAbCXHCie2k`
    //   )
    // }

    // this will add keyboard event listner to the app
    document.addEventListener('keydown', this.handleKeyDown)
  }

  async componentDidUpdate(
    prevProps: Readonly<IMainLayoutProps>
  ): Promise<void> {
    const { actions, reverseFeedAlertData } = this.props
    const { alertTime } = reverseFeedAlertData
    const { reverseFeedAlertData: prevReverseFeedAlertData } = prevProps
    const { alertTime: prevAlertTime } = prevReverseFeedAlertData

    if (alertTime && alertTime !== prevAlertTime) {
      const reverseFeedMessage = JSON.stringify(reverseFeedAlertData)
      enqueueNotistack({
        message: reverseFeedMessage,
        variant: 'custom',
        autoHideDuration: 6000,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      })

      await actions.getNotifications({})
    }

    // this will add keyboard event listner to the app
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  setOpen = (e: boolean) => {
    this.setState({ open: e })
  }
  navigateTo = (route: To) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }
  handleKeyDown = (event: any) => {
    if (event.key === 'F3') {
      event.preventDefault()
      this.handleNavigateToOrderBook()
    }
    if (
      ((event.ctrlKey || event.metaKey) && event.key === 'f') ||
      (event.shiftKey && event.key === 'F8')
    ) {
      event.preventDefault()
      this.showSearchPopup()
    }
    if (event.ctrlKey && event.key === 'p') {
      event.preventDefault()
      this.navigateToPositions()
    }
    if (event.ctrlKey && event.shiftKey && event.key === 'F2') {
      event.preventDefault()
      this.navigateToFunds()
    }
    if (event.alt && event.key === 'F6') {
      event.preventDefault()
      this.navigateToTodaysPositions()
    }
  }
  handleNavigateToOrderBook = () => {
    const { navigateTo } = this.props
    const status = 'Pending'
    const options = {
      state: { status }
    }
    navigateTo(APP_ROUTES.ORDERBOOK.pathname, options)
    window.scrollTo(0, 0)
  }
  navigateToTodaysPositions = () => {
    const { actions } = this.props
    actions.setPortfolioTabIndicator(1)
    actions.setPortfolioIndicator(1)
    this.navigateTo('/portfolio/stocks')
    window.scrollTo(0, 0)
  }
  navigateToPositions = () => {
    const { actions } = this.props
    actions.setPortfolioTabIndicator(1)
    this.navigateTo('/portfolio/stocks')
    window.scrollTo(0, 0)
  }
  navigateToFunds = () => {
    this.navigateTo(APP_ROUTES.FUNDSDASHBOARD.pathname)
    window.scrollTo(0, 0)
  }
  showSearchPopup = () => {
    this.setState({ showSearch: true })
  }

  handleGetEquityLimits = async () => {
    const { actions, handleError } = this.props
    const requestPayload = { segment: 'EQ' }
    const response = await actions.getAvailableEquityLimits(requestPayload)
    if (response._isCustomError) {
      // handleError(response)
      console.log('failed API - getAvailableEquityLimits')
      return
    }
  }

  handleGetCommodityLimits = async () => {
    const { actions, handleError } = this.props
    const requestPayload = { segment: 'COMM' }
    const response = await actions.getAvailableCommodityLimits(requestPayload)
    if (response._isCustomError) {
      // handleError(response)
      console.log('failed API - getAvailableCommodityLimits')
      return
    }
  }

  handleGetCustomerProfileDetails = async () => {
    const { actions, handleError, authenticateSSOSelector } = this.props
    const response = await actions.getCustomerProfileDetails()
    if (response._isCustomError) {
      // return handleError(response)
      console.log('failed API - getCustomerProfileDetails')
    } else {
      if (USE_CLEVERTAP === 'true') {
        const { subAccountId, segmentsEnabled, phoneNo } = response
        const { lastLoggedInAt } = authenticateSSOSelector?.metadata
        const activeSegments = Object.entries(segmentsEnabled)
          .filter(([key, value]) => value === true)
          .map(([key, value]) => key)

        ;(window as any)?.clevertap?.onUserLogin?.push({
          Site: {
            Identity: subAccountId,
            Entity_Id: subAccountId,
            Active_Segments: activeSegments,
            Last_Active: lastLoggedInAt
          }
        })
      }
    }
  }

  handleGetHoldingsSummaryDetails = async () => {
    const { actions } = this.props
    const requestData: getHoldingsSummaryPayload = {
      segment: 'EQ'
    }
    const getHoldingsSummaryResponse =
      await actions.getHoldingsSummary(requestData)
    if (getHoldingsSummaryResponse._isCustomError) {
      console.log('failed API - getHoldingsSummaryResponse')
      return
    }
  }

  handleShowMobileLayout = () => {
    const url = window.location.href
    const pathname = new URL(url).pathname
    const regexPattern = new RegExp(APP_PAGES_FOR_MOBILE_LAYOUT.join('|'))
    if (regexPattern.test(pathname)) {
      return false
    }
    return !FULL_SCREEN.includes(pathname)
  }

  handleDeepLink = () => {
    const { deepLinkAttributes, actions } = this.props
    if (deepLinkAttributes) {
      const { path, parameterList } = deepLinkAttributes
      if (path === DEEP_LINK_KEYS.ORDER_FORM) {
        const parameter1 = parameterList[0]
        const parameter2 = parameterList[1]
        if (
          parameter1 &&
          parameter2 &&
          (parameter2 === 'BUY' || parameter2 === 'SELL')
        ) {
          // write logic to open order form
          this.handleBuySell(parameter2, parameter1)
        }
        actions.resetDeepLinkData()
      }
    }
  }

  handleBuySell = (value: string, selectedScript: string) => {
    const { actions, navigate, breakpoints = {} } = this.props
    const { exchange } =
      (window as any).secMaster.getByScriptId(selectedScript) || {}
    if (!exchange) {
      const { isDesktop } = getDeviceActiveScreen(breakpoints)
      if (!isDesktop) {
        navigate(APP_ROUTES.MOBILE_WATCHLIST.pathname)
        return
      }
      return
    }

    actions.updateOrderPageModalIndicator({
      orderPageModalIndicator: true,
      orderScriptId: selectedScript,
      orderBuyOrSell: value,
      selectedExchange: exchange,
      isModifyOrderRequest: false,
      isRepeatOrderRequest: false
    })
  }

  render() {
    const {
      breakpoints = {},
      isShowPostLoginErrorTrue,
      isNSEFOEnabledSelector,
      alertFormDetails,
      orderPageSipModalIndicator
    } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    const { showSearch } = this.state
    const getLocalStorageData =
      localStorage.getItem('haveSeenRiskDisclosure') ?? false
    const haveSeenRiskDisclosure = JSON.parse(getLocalStorageData as any)
    const showDisclosure = !haveSeenRiskDisclosure && isNSEFOEnabledSelector

    return (
      <>
        {showDisclosure && <RiskDisclosure />}
        <DsStack sx={{ position: 'relative' }}>
          {isDesktop && (
            <DsStack
              sx={{
                position: 'fixed',
                width: '100vw',
                top: 0,
                zIndex: 2,
                height:
                  'calc(var(--ds-spacing-superheated) + var(--ds-spacing-quickFreeze))'
              }}
            >
              <Header />
            </DsStack>
          )}

          {this.handleShowMobileLayout() && (
            <DsStack
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: 2,
                display: { md: 'none', xs: 'flex' }
              }}
            >
              <MobileHeader />
            </DsStack>
          )}

          <DsStack
            direction='row'
            spacing={isDesktop ? 'var(--ds-spacing-mild)' : 0}
            sx={{
              justifyContent: 'flex-end',
              mt: {
                md: 'calc(var(--ds-spacing-superheated) + var(--ds-spacing-warm))'
              },
              mr: { md: 'var(--ds-spacing-mild)' },
              mb: { md: 'var(--ds-spacing-mild)' },
              ml: {
                md: 'var(--ds-spacing-mild)'
              }
            }}
          >
            {isDesktop && (
              <DsBox
                sx={{
                  width: { lg: '448px', md: '38%' },
                  height: isDesktop ? '100vh' : 'calc(100vh - 72px)',
                  display: { md: 'flex', xs: 'none' },
                  flexDirection: 'column',
                  position: 'fixed',
                  left: 'var(--ds-spacing-mild)'
                }}
              >
                <Sidebar />
              </DsBox>
            )}

            <DsBox
              sx={{
                width: {
                  xs: '100%',
                  md: 'calc(62% - 48px)',
                  lg: 'calc(100% - 470px)'
                }
              }}
            >
              <Outlet />
            </DsBox>
          </DsStack>

          {this.handleShowMobileLayout() && (
            <DsStack sx={{ position: 'sticky', bottom: 0, zIndex: 3 }}>
              <BottomNavigation />
            </DsStack>
          )}

          <OrderModal />
          {orderPageSipModalIndicator && <SipModal />}
          <AlertsFormModal alertFormDetails={alertFormDetails} />
          {isShowPostLoginErrorTrue && <PostLoginErrors />}
        </DsStack>
        {showSearch && (
          <DsPopup
            showClose={true}
            open={true}
            onClose={() => this.setState({ showSearch: false })}
            sx={{
              '.MuiDialogActions-root': {
                marginTop: 'var(--ds-spacing-zero)'
              },
              '.MuiDialogContent-root': {
                marginTop: 'var(--ds-spacing-zero)',
                height: '33rem'
              },
              display: { md: 'block', xs: 'none' }
            }}
          >
            <SearchStocks
              setOpen={this.setOpen}
              open={true}
              isShortcutSearch={true}
              shortcutCallback={() => this.setState({ showSearch: false })}
            />
          </DsPopup>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const isShowPostLoginErrorTrue = getShowPostLoginError(state)
  const isNSEFOEnabledSelector = isNSEFOEnabled(state)
  const getSubAccountId = getSubAccountIdSelector(state)
  const getCommodityValueSelect = getCommodityValueSelector(state)
  const getEquityValueSelect = getEquityValueSelector(state)
  const deepLinkAttributes = getDeepLinkAttributes(state)
  const alertFormDetails = getAlertsFormDetails(state)
  const authenticateSSOSelector = getSSOAuthenticateData(state)
  const orderPageSipModalIndicator = getOrderPageSipModalIndicator(state)
  const reverseFeedAlertData = getReverseFeedAlertData(state)
  return {
    isShowPostLoginErrorTrue,
    isNSEFOEnabledSelector,
    getSubAccountId,
    getEquityValueSelect,
    getCommodityValueSelect,
    deepLinkAttributes,
    alertFormDetails,
    authenticateSSOSelector,
    orderPageSipModalIndicator,
    reverseFeedAlertData
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  actions: {
    getCustomerProfileDetails: () =>
      dispatch(getCustomerProfileDetailsAction()),
    setTabIndicator: (tab: number) => dispatch(setTabIndicator(tab)),
    getAvailableEquityLimits: (data: any) =>
      dispatch(getAvailableEquityLimitsAction(data)),
    getAvailableCommodityLimits: (data: any) =>
      dispatch(getAvailableCommodityLimitsAction(data)),
    resetDeepLinkData: () => dispatch(resetDeepLinkData(null)),
    updateOrderPageModalIndicator: (
      requestData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
    ) => dispatch(updateOrderPageModalIndicator(requestData)),
    getNotifications: (data: any) => dispatch(getNotificationsAction(data)),
    setPortfolioTabIndicator: (tab: number) =>
      dispatch(setPortfolioTabIndicator(tab)),
    setPortfolioIndicator: (val: number) =>
      dispatch(setPortfolioIndicator(val)),
    getHoldingsSummary: (requestData: getHoldingsSummaryPayload) =>
      dispatch(getHoldingsSummaryAction(requestData))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(MainLayout)
)
