import { createSelector } from '@reduxjs/toolkit'
export const SLICE_NAME = 'research'

const select = (state: any) => state[SLICE_NAME]

export const getResearchConfig = createSelector(
  select,
  research => research?.configData
)

export const getResearchData = createSelector(
  select,
  research => research?.data || []
)

export const getSimilarResearchData = createSelector(
  select,
  research => research?.similarResearchData || []
)

const _getDataByScriptId = (data: any[], scriptId: any) => {
  return data.filter(item =>
    item.exchangeDetails.some(
      (exchange: { scriptId: any }) => exchange?.scriptId === scriptId
    )
  )
}

export const getResearchDataByScriptId = (scriptId: any) =>
  createSelector(
    select,
    research => _getDataByScriptId(research?.data, scriptId)[0] || {}
  )

export const getConfigTs = createSelector(
  select,
  research => research?.configTs
)

export const getCategory = createSelector(
  select,
  research => research?.category
)

export const getRecommendationFilters = createSelector(
  select,
  research => research?.recommendationFilter
)

export const getSubCategory = createSelector(
  select,
  research => research?.subCategory
)
