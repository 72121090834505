import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { Formik, FormikProps } from 'formik'
import {
  DsStack,
  DsTab,
  DsTabs,
  enqueueNotistack
} from '@am92/react-design-system'
import {
  getCurrentModifyOrderResponse,
  getOrderBuyOrSell,
  getOrderFormData,
  getOrderScriptId,
  getPostLoginErrorCode,
  isModifyOrderRequest,
  isRepeatOrderRequest
} from '~/src/Redux/Orders/selectors'
import orderFormSchema from './OrderForm.Schema'
import QuantitySection from './QuantitySection'
import {
  UUIDGenerator_V4,
  getProductType,
  getScriptPriceRange,
  getTypeOfOrder,
  tickerValue,
  triggerCleverTapEvent
} from '~/src/Utils/global'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import PriceAndMarketDepthSection from './PriceAndMarketDepthSection'
import OrderTypeSection from './OrderTypeSection'
import AdvanceOptionsNew from './AdvanceOptionsNew'
import BnplSection from './BnplSection'
import ViewTaxCharges from './ViewTaxChargesModal'
import OrderformBottomSection from './OrderFormBottomSection'
import placeOrderAction, {
  T_PLACE_ORDER_PAYLOAD
} from '~/src/Redux/Orders/Services/placeOrder.Service'
import GratificationDialog from '../../GratificationDialog/GratificationDialog'
import { GRATIFICATION_CASES } from '../../GratificationDialog/GratificationScenarios'
import getOrderBookAction, {
  T_GET_ORDER_BOOK_PAYLOAD
} from '~/src/Redux/Orders/Services/getOrderBook.Service'
import {
  changeOrderbookTab,
  changeOrderFormData,
  handleOpenPostLoginError,
  resetOrderData,
  updateOrderPageModalIndicator,
  updateSectionType
} from '~/src/Redux/Orders/reducer'
import modifyOrderAction, {
  T_MODIFY_ORDER_PAYLOAD
} from '~/src/Redux/Orders/Services/modifyOrder.Service'
import { T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD } from '~/src/Pages/Orderbook/Orderbook.Page'
import SellableQtyAndEncash from './SellableQtyAndEncash'
import {
  getDpIdAndEdisFlagOfCustomer,
  isGTDAllowedSelector,
  isHRTAllowed
} from '~/src/Redux/Customer/Selectors'
import PostAPIResponseDialog, {
  TPostAPIResponseDialogProps
} from '../../PostAPIResponseDialog/PostAPIResponseDialog'
import {
  EDIS_AUTH,
  INSUFFICIENT_FUND,
  ORDER_FAILED
} from '~/src/Constants/ASSET_MAP'
import { INITIATE_REDIRECTION_LINK } from '~/src/Configurations/env'
import {
  getSSOAuthenticateData,
  getSubAccountIdSelector
} from '~/src/Redux/SSO/Selectors'
import ssoInitiateAction from '~/src/Redux/SSO/Services/loginInitiate.Service'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import DisclosedQty from './DisclosedQty'
import ChooseValidity from './ChooseValidity'
import { format } from 'date-fns'
import GtdNote from './GtdNote'
import { getThemeReducer } from '~/src/Redux/Theme/Selectors'
import { getHoldingAndAveragePrice } from '~/src/Redux/Dashboard/Selectors'
import { ORDERBOOK_TAB } from '~/src/Pages/Orderbook/Orderbook.constants'

type ActionTypes = {
  placeOrder: (data: T_PLACE_ORDER_PAYLOAD) => Promise<any>
  getOrderBook: (data: T_GET_ORDER_BOOK_PAYLOAD) => Promise<any>
  updateOrderPageModalIndicator: (
    requestData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
  ) => Promise<any>
  resetOrderData: (requestData: string) => Promise<any>
  modifyOrder: (data: T_MODIFY_ORDER_PAYLOAD) => any
  authoriseSecurities: (data: any) => any
  handleOpenPostLoginError: (requestData: string) => Promise<any>
  addFunds: (data: any) => Promise<any>
  updateSectionType: (requestData: number) => Promise<any>
  changeOrderFormData: (payload: { key: string; data: any }) => Promise<any>
  changeOrderbookTab: (orderbookTab: string) => Promise<any>
}

export interface FormValues {
  quantity: number
  price: string | number
  marketDepth: string
  orderType: string
  triggerPrice: number
  validity: string
  disclosedQty: number
  afterMarketOrder: boolean
  sellableQtyType: string
  encash: boolean
  bnpl: boolean
  sectionType: number
  goodTillDate: string
}

export interface IOrderFormSectionProps extends IWithRouterProps {
  actions: ActionTypes
  formRef: React.Ref<any>
  orderData: FormValues
  orderScriptId: string
  stockDetails: T_SCRIPTDATA
  orderBuyOrSell: string
  isModifyOrder: boolean
  isRepeatOrder: boolean
  isHRTAllowedSelect: boolean
  getCurrentModifyOrder: any
  handleError: (res: any) => void
  authenticateSSOSelector: any
  dpId: string
  subAccountId: string
  postLoginErrorCode: number
  validity?: any
  isGTDAllowed: boolean
  theme: string
  holdings: number | string
}

export type TOrderFormSectionState = {
  priceBoxFocused: boolean
  showGratification: boolean
  openSurveillanceAlert: boolean
  openMarketDepth: boolean
  formReset: boolean
  errorJSON: TPostAPIResponseDialogProps
}
class OrderFormSection extends Component<
  IOrderFormSectionProps,
  TOrderFormSectionState
> {
  constructor(props: IOrderFormSectionProps) {
    super(props)
    this.state = {
      priceBoxFocused: false,
      showGratification: false,
      openSurveillanceAlert: false,
      openMarketDepth: false,
      formReset: false,
      errorJSON: {
        renderPostAPIDialog: false
      }
    }
  }
  componentDidMount(): void {
    const { orderData } = this.props
    const { marketDepth } = orderData
    if (marketDepth === 'limit') this.setState({ priceBoxFocused: true })
  }
  async componentDidUpdate(
    prevProps: Readonly<IOrderFormSectionProps>
  ): Promise<void> {
    const { orderScriptId, orderBuyOrSell, formRef, orderData } = this.props
    const { marketDepth } = orderData

    const {
      orderScriptId: PREV_ORDERSCRIPTID,
      orderBuyOrSell: PREV_ORDER_BUY_SELL
    } = prevProps
    if (
      PREV_ORDERSCRIPTID !== orderScriptId ||
      orderBuyOrSell !== PREV_ORDER_BUY_SELL
    ) {
      await (formRef as any)?.current.resetForm({ errors: {} })
      this.setState({ priceBoxFocused: false })
      this.handleCloseMarketDepth()
    }
    if (marketDepth !== prevProps.orderData.marketDepth) {
      if (marketDepth === 'limit') this.setState({ priceBoxFocused: true })
    }
  }

  handleSubmit = async (values: FormValues) => {
    const { isModifyOrder, postLoginErrorCode, actions } = this.props
    if (postLoginErrorCode) {
      actions.handleOpenPostLoginError('open')
      return
    }
    ;(isModifyOrder && this.handleModifyOrder(values)) ||
      this.handlePlaceOrder(values)
  }

  handlePlaceOrder = async (values: FormValues) => {
    const {
      quantity,
      price,
      marketDepth,
      orderType,
      triggerPrice,
      validity,
      disclosedQty,
      afterMarketOrder,
      bnpl,
      sectionType,
      goodTillDate
    } = values
    const { stockDetails, orderBuyOrSell, orderScriptId, actions, navigateTo } =
      this.props
    const { segment, exchange, lotsize } = stockDetails || {}

    const isSegmentEquity = segment === 'EQ'
    const isMarketDepthMarket = marketDepth === 'market'
    const isGtdOrder = sectionType === 1
    const isBUY = orderBuyOrSell === 'BUY'
    const isOrderTypeDelivery = orderType === 'DELIVERY'
    const isBnplOrderType = bnpl && isOrderTypeDelivery

    const typeOfOrder = getTypeOfOrder(triggerPrice, isMarketDepthMarket)
    const productType = isBUY
      ? isBnplOrderType
        ? 'MTF'
        : orderType
      : getProductType(values)
    const totalQty =
      (isSegmentEquity && Number(quantity)) ||
      Number(quantity) * Number(lotsize)
    const exchangeSegmentCombination =
      ((exchange === 'MCX' || exchange == 'NCDEX') && `${exchange}_FO`) ||
      `${exchange}_${segment}`
    const validityType = (isGtdOrder && 'GTDt') || validity.toUpperCase()
    const orderPrice =
      (isMarketDepthMarket && Number(tickerValue(orderScriptId))) ||
      Number(price)
    const isGtdOrderOutsideDpr =
      isGtdOrder &&
      (getScriptPriceRange(orderScriptId)[1] < price ||
        price < getScriptPriceRange(orderScriptId)[0])

    const placeOrderData: T_PLACE_ORDER_PAYLOAD = {
      orderRefId: UUIDGenerator_V4(),
      segment,
      exchange: exchangeSegmentCombination,
      scriptId: orderScriptId.toString(),
      transactionType: orderBuyOrSell,
      productType,
      orderType: typeOfOrder,
      totalQty,
      disclosedQty: Number(disclosedQty),
      orderPrice,
      triggerPrice: Number(triggerPrice),
      validityType,
      validityDays: 5,
      isAmo: afterMarketOrder,
      ...(isGtdOrder && {
        goodTillDate: format(new Date(goodTillDate), 'yyyyMMdd')
      })
    }
    const placeOrderResponse = await actions.placeOrder(placeOrderData)
    if (placeOrderResponse._isCustomError) {
      this.handlePlaceOrderError(placeOrderResponse, isBUY, totalQty)
      const { error = {} } = placeOrderResponse
      const { code } = error
      const customAttributesFailed = {
        'Event Status': 'Fail',
        'Screen Name': 'Order Form',
        Action: orderBuyOrSell,
        Reason: code
      }
      triggerCleverTapEvent('Transation_Done', customAttributesFailed)
      // enqueueNotistack({
      //   message: 'Place Order API Failed.',
      //   autoHideDuration: 2000
      // })
      this.handleCloseSurveillanceAlert()
      return
    }

    const customAttributes = {
      'Event Status': 'Pass',
      'Screen Name': 'Order Form',
      Action: orderBuyOrSell,
      'Scrip Id': orderScriptId.toString(),
      'Disclosed Qty': (Number(disclosedQty) && 'Yes') || 'No',
      'Trigger Price': (Number(triggerPrice) && 'Yes') || 'No',
      Exchange: exchange,
      'Price Type': typeOfOrder,
      Product: productType,
      Validity: validityType
    }
    triggerCleverTapEvent('Transation_Done', customAttributes)

    this.setState({ showGratification: true }, () => {
      setTimeout(() => {
        this.handleCloseGratification()
        // close order form
        this.handleCloseOrderForm()
        // reset order data
        actions.resetOrderData('resetOrderData')
        // close surveillance alert
        this.handleCloseSurveillanceAlert()
        // navigateTo orderbook page
        if (isGtdOrderOutsideDpr) {
          actions.changeOrderbookTab(ORDERBOOK_TAB.GTD.value)
          navigateTo(APP_ROUTES.ORDERBOOK.pathname)
        } else {
          navigateTo(APP_ROUTES.ORDERBOOK.pathname)
        }
        // fetch latest orderbook data
        this.fetchOrderBook()
      }, 2000)
    })
  }

  handleModifyOrder = async (values: FormValues) => {
    const {
      quantity,
      price,
      marketDepth,
      triggerPrice,
      validity,
      disclosedQty = 0,
      afterMarketOrder,
      sectionType,
      goodTillDate = ''
    } = values
    const { stockDetails, orderScriptId, actions, getCurrentModifyOrder } =
      this.props
    const { segment, lotsize } = stockDetails || {}
    const {
      omsOrderId,
      omsOrderSerialNumber,
      exchange,
      transactionType,
      product,
      tradedQty
    } = getCurrentModifyOrder

    const isSegmentEquity = segment === 'EQ'
    const isMarketDepthMarket = marketDepth === 'market'
    const isProductTypeEmargin = product === 'EMARGIN'
    const isGtdOrder = sectionType === 1

    const orderPrice =
      (isMarketDepthMarket && Number(tickerValue(orderScriptId))) ||
      Number(price)
    const totalQty =
      (isSegmentEquity && Number(quantity)) ||
      Number(quantity) * Number(lotsize)
    const typeOfOrder = getTypeOfOrder(triggerPrice, isMarketDepthMarket)
    const validityType = (isGtdOrder && 'GTDt') || validity.toUpperCase()
    const productType = (isProductTypeEmargin && 'MTF') || product
    const tradedQuantity = (tradedQty && tradedQty) || 0

    const modifyOrderPayload: T_MODIFY_ORDER_PAYLOAD = {
      omsOrderId,
      omsOrderSerialNumber,
      segment,
      exchange,
      scriptId: orderScriptId,
      transactionType,
      productType,
      orderType: typeOfOrder,
      totalQty,
      disclosedQty: Number(disclosedQty),
      orderPrice,
      triggerPrice: Number(triggerPrice),
      validityType,
      validityDays: 5,
      openQty: totalQty,
      tradedQty: tradedQuantity,
      isAmo: afterMarketOrder,
      ...(isGtdOrder && {
        goodTillDate: format(new Date(goodTillDate), 'yyyyMMdd')
      })
    }
    const modifyOrderResponse = await actions.modifyOrder(modifyOrderPayload)
    if (modifyOrderResponse._isCustomError) {
      enqueueNotistack({
        message: 'Modify Order API Failed.',
        autoHideDuration: 2000
      })
      const { error = {} } = modifyOrderResponse
      const { code } = error
      const customAttributesFailed = {
        'Event Status': 'Fail',
        'Screen Name': 'Order Form',
        Action: transactionType,
        Reason: code
      }
      triggerCleverTapEvent('Transation_Done', customAttributesFailed)
      this.handleCloseSurveillanceAlert()
      return
    }

    const customAttributes = {
      'Event Status': 'Pass',
      'Screen Name': 'Order Form',
      Action: transactionType,
      'Scrip Id': orderScriptId.toString(),
      'Disclosed Qty': Number(disclosedQty) ? 'Yes' : 'No',
      'Trigger Price': Number(triggerPrice) ? 'Yes' : 'No',
      Exchange: exchange,
      'Price Type': typeOfOrder,
      Product: product,
      Validity: validityType
    }
    triggerCleverTapEvent('Transation_Done', customAttributes)

    enqueueNotistack({
      message: 'Order modified succesfully',
      autoHideDuration: 2000
    })
    // reset order data
    actions.resetOrderData('resetOrderData')
    // close order form
    this.handleCloseOrderForm()
    // close surveillance alert
    this.handleCloseSurveillanceAlert()
    // fetch latest orderbook data
    this.fetchOrderBook()
  }

  fetchOrderBook = async () => {
    const { actions } = this.props
    const requestPayload: T_GET_ORDER_BOOK_PAYLOAD = {
      segment: 'EQ',
      omsOrderId: '',
      orderStatus: '-1'
    }

    const getOrderBookResponse = await actions.getOrderBook(requestPayload)
    if (getOrderBookResponse?._isCustomError) {
      enqueueNotistack({
        message: 'Get order book API Failed.',
        autoHideDuration: 2000
      })
      console.log('failed API - getOrderBook')
      return
    }
  }

  handlePriceBoxFocus = async () => {
    const { priceBoxFocused } = this.state
    this.setState({ priceBoxFocused: !priceBoxFocused })
  }

  handleDpr = (value: boolean) => {
    this.setState({ priceBoxFocused: value })
  }

  handleCloseOrderForm = () => {
    const { actions } = this.props
    actions.updateOrderPageModalIndicator({
      orderPageModalIndicator: false,
      orderScriptId: '',
      orderBuyOrSell: '',
      isModifyOrderRequest: false,
      isRepeatOrderRequest: false
    })
  }

  handleOpenSurveillanceAlert = () => {
    this.setState({ openSurveillanceAlert: true })
  }

  handleCloseSurveillanceAlert = () => {
    this.setState({ openSurveillanceAlert: false })
  }

  handleOpenMarketDepth = (value: string) => {
    const customAttributes = {
      'Event Status': 'Pass',
      'Screen Name': 'Order Form',
      Action: value
    }
    triggerCleverTapEvent('Transaction_Market_Depth', customAttributes)
    this.setState({ openMarketDepth: true })
  }

  handleCloseMarketDepth = () => {
    this.setState({ openMarketDepth: false })
  }

  handleCloseGratification = () => {
    this.setState({ showGratification: false })
  }

  handleClickOnAuthorize = async (totalQty: number) => {
    const {
      actions,
      handleError,
      authenticateSSOSelector,
      dpId,
      subAccountId,
      orderScriptId,
      theme
    } = this.props
    const { refreshToken } = authenticateSSOSelector
    const { token } = refreshToken
    const requestPayload = {
      type: 'EDIS',
      redirectURL: INITIATE_REDIRECTION_LINK,
      refreshToken: token,
      metadata: {
        createLinkPayload: {
          securities: [
            {
              scriptId: orderScriptId,
              quantity: totalQty
            }
          ],
          dpId
        },
        from: 'App',
        type: 'edis',
        subAccountId,
        redirectionURL: INITIATE_REDIRECTION_LINK,
        source: 'web',
        theme
      }
    }

    const authoriseSecuritiesResponse =
      await actions.authoriseSecurities(requestPayload)
    if (authoriseSecuritiesResponse._isCustomError) {
      console.log('failed API - authoriseSecurities')

      return handleError(authoriseSecuritiesResponse)
    }
    const { redirectURL } = authoriseSecuritiesResponse
    window.open(redirectURL, '_self')
  }

  handleTabChange = async (
    e: React.SyntheticEvent<Element, Event>,
    newvalue: number
  ) => {
    // write action to change the tab here
    const { actions } = this.props
    await actions.updateSectionType(newvalue)
    await actions.changeOrderFormData({
      key: 'marketDepth',
      data: newvalue ? 'limit' : 'market'
    })
  }

  renderForm = (formikBag: FormikProps<FormValues>) => {
    const { handleChange, handleSubmit, errors, values, setFieldValue } =
      formikBag
    const {
      stockDetails,
      orderBuyOrSell,
      orderScriptId,
      isModifyOrder,
      isRepeatOrder,
      isGTDAllowed
    } = this.props
    const { asm_flag: isSurveillanceAlertNeeded, segment = '' } =
      stockDetails || {}
    const { priceBoxFocused, openSurveillanceAlert, openMarketDepth } =
      this.state

    const quantityError = errors.quantity ? true : false
    const quantityHelperText = errors.quantity

    const priceError = errors.price ? true : false
    const priceHelperText = errors.price

    const triggerPriceError = errors.triggerPrice ? true : false
    const triggerPriceHelperText = errors.triggerPrice

    const disclosedQtyError = errors.disclosedQty ? true : false
    const disclosedQtyHelperText = errors.disclosedQty

    const isSELL = orderBuyOrSell === 'SELL'
    const isEquitySegment = segment === 'EQ'
    const showSellableRow = isSELL && isEquitySegment

    return (
      <form
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          animation: 'reverse',
          overflow: 'hidden',
          overflowY: 'scroll',
          overscrollBehavior: 'contain'
        }}
      >
        {isGTDAllowed && !isModifyOrder && (
          <DsTabs
            value={values.sectionType}
            onChange={this.handleTabChange}
            textColor='secondary'
            indicatorColor='secondary'
            variant={'fullWidth'}
            sx={{
              '.MuiTab-root': {
                color: 'var(--ds-colour-typoTertiary) !important'
              },
              '.Mui-selected': {
                color: 'var(--ds-colour-supportPositive) !important'
              },
              '.MuiTabs-indicator': {
                backgroundColor: 'var(--ds-colour-supportPositive)'
              },
              '.MuiTouchRipple-root': {
                borderBottom: '1px solid var(--ds-colour-stateDisabledSurface)'
              }
            }}
          >
            <DsTab value={0} label='Normal' />
            <DsTab value={1} label='GTD (Till Date)' />
          </DsTabs>
        )}
        <DsStack
          sx={{
            marginTop: 'var(--ds-spacing-mild)',
            marginBottom: 'var(--ds-spacing-mild)'
          }}
          spacing={8}
        >
          <QuantitySection
            quantityError={quantityError}
            quantityHelperText={quantityHelperText}
            handleChange={handleChange}
            quantity={values.quantity}
            stockDetails={stockDetails}
            isModifyOrder={isModifyOrder}
            isRepeatOrder={isRepeatOrder}
            setFieldValue={setFieldValue}
          />
          <PriceAndMarketDepthSection
            priceError={priceError}
            priceHelperText={priceHelperText}
            handleChange={handleChange}
            price={values.price}
            sectionType={values.sectionType}
            marketDepth={values.marketDepth}
            stockDetails={stockDetails}
            orderBuyOrSell={orderBuyOrSell}
            handlePriceBoxFocus={this.handleDpr}
            openMarketDepth={openMarketDepth}
            handleOpenMarketDepth={this.handleOpenMarketDepth}
            handleCloseMarketDepth={this.handleCloseMarketDepth}
            orderScriptId={orderScriptId}
          />
          {(values.sectionType === 0 && (
            <>
              <DsStack spacing={4}>
                <OrderTypeSection
                  orderType={values.orderType}
                  orderBuyOrSell={orderBuyOrSell}
                  setFieldValue={setFieldValue}
                  isModifyOrder={isModifyOrder}
                  isEquitySegment={isEquitySegment}
                />
                <BnplSection
                  orderBuyOrSell={orderBuyOrSell}
                  orderType={values.orderType}
                  stockDetails={stockDetails}
                  orderScriptId={orderScriptId}
                  bnpl={values.bnpl}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  isModifyOrder={isModifyOrder}
                />
              </DsStack>
              {(showSellableRow && (
                <SellableQtyAndEncash
                  orderBuyOrSell={orderBuyOrSell}
                  orderScriptId={orderScriptId}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  sellableQtyType={values.sellableQtyType}
                  showSellableRow={showSellableRow}
                  encash={values.encash}
                  orderType={values.orderType}
                  isModifyOrder={isModifyOrder}
                  formikBag={formikBag}
                />
              )) ||
                null}
              <AdvanceOptionsNew
                triggerPriceError={triggerPriceError}
                triggerPriceHelperText={triggerPriceHelperText}
                triggerPrice={values.triggerPrice}
                disclosedQtyError={disclosedQtyError}
                disclosedQtyHelperText={disclosedQtyHelperText}
                disclosedQty={values.disclosedQty}
                validity={values.validity}
                afterMarketOrder={values.afterMarketOrder}
                handleChange={handleChange}
                orderBuyOrSell={orderBuyOrSell}
                setFieldValue={setFieldValue}
                orderScriptId={orderScriptId}
                stockDetails={stockDetails}
              />
            </>
          )) ||
            null}
          {(values.sectionType === 1 && (
            <DsStack
              sx={{
                paddingX: {
                  md: 'var(--ds-spacing-mild)',
                  xs: 'var(--ds-spacing-bitterCold)'
                }
              }}
              spacing={8}
            >
              <ChooseValidity
                goodTillDate={values.goodTillDate}
                handleChange={handleChange}
              />
              <DisclosedQty
                handleChange={handleChange}
                disclosedQty={values.disclosedQty}
                disclosedQtyError={disclosedQtyError}
                disclosedQtyHelperText={disclosedQtyHelperText}
              />
              <GtdNote />
            </DsStack>
          )) ||
            null}
          <ViewTaxCharges
            values={values}
            stockDetails={stockDetails}
            scriptId={orderScriptId}
            orderBuyOrSell={orderBuyOrSell}
            marketDepth={values.marketDepth}
          />
        </DsStack>
        <OrderformBottomSection
          orderBuyOrSell={orderBuyOrSell}
          isSurveillanceAlertNeeded={isSurveillanceAlertNeeded}
          handlePlaceOrModifyOrder={this.handleSubmit}
          priceBoxFocused={priceBoxFocused}
          scriptId={orderScriptId}
          values={values}
          stockDetails={stockDetails}
          openSurveillanceAlert={openSurveillanceAlert}
          handleSurveillanceAlert={this.handleOpenSurveillanceAlert}
          handleCloseSurveillanceAlert={this.handleCloseSurveillanceAlert}
          segment={segment}
          isBmpl={values.bnpl}
        />
      </form>
    )
  }

  handlePlaceOrderError = (
    placeOrderResponse: any,
    isBUY: boolean,
    totalQty: number
  ) => {
    const { error = {}, data = {} } = placeOrderResponse
    const { shortFallValue = '' } = data
    const { code, error: ERROR_MESSAGE } = error
    let errorJSON

    if (isBUY) {
      if (code === 'ERR_NO_3_IN_1') {
        // trigger funds journey
        errorJSON = {
          onClose: this.handleEmptyErrorJSON,
          type: 'variable',
          image: INSUFFICIENT_FUND,
          heading: 'Insufficient Funds',
          description: `Add ${shortFallValue} shortage funds from your mapped bank account to execute this order.`,
          primaryText: 'Add Funds',
          primaryAction: this.handleAddFunds,
          secondaryText: 'Close',
          secondaryAction: this.handleEmptyErrorJSON,
          renderPostAPIDialog: true
        }
        this.setState({ errorJSON })
        return
      }

      if (shortFallValue) {
        errorJSON = {
          onClose: this.handleEmptyErrorJSON,
          type: 'variable',
          image: INSUFFICIENT_FUND,
          heading: 'Order Failed !',
          description:
            'Order cannot be placed due to insufficient funds. Please add funds to your default bank account and try again.',
          primaryText: 'Go Back',
          primaryAction: this.handleEmptyErrorJSON,
          secondaryText: 'Close',
          secondaryAction: this.handleEmptyErrorJSON,
          renderPostAPIDialog: true
        }
        this.setState({ errorJSON })
        return
      }

      errorJSON = {
        onClose: this.handleEmptyErrorJSON,
        type: 'variable',
        image: ORDER_FAILED,
        heading: 'Order Failed',
        description: ERROR_MESSAGE,
        primaryText: 'Go Back',
        primaryAction: this.handleEmptyErrorJSON,
        secondaryText: 'Close',
        secondaryAction: this.handleEmptyErrorJSON,
        renderPostAPIDialog: true
      }
      this.setState({ errorJSON })
      return
    }

    if (code === 'ERR_NO_POA') {
      // trigger securities journey
      errorJSON = {
        onClose: this.handleEmptyErrorJSON,
        type: 'dualAction',
        image: EDIS_AUTH,
        heading: 'Requesting Authorisation',
        description: `Please authorise this stock at NSDL (your DEMAT depository) to complete the sell transaction.`,
        primaryText: 'Authorise',
        secondaryText: 'Close',
        primaryAction: () => this.handleClickOnAuthorize(totalQty),
        secondaryAction: this.handleEmptyErrorJSON,
        renderPostAPIDialog: true
      }
      this.setState({ errorJSON })
      return
    }

    errorJSON = {
      onClose: this.handleEmptyErrorJSON,
      type: 'variable',
      image: ORDER_FAILED,
      heading: 'Order Failed',
      description: ERROR_MESSAGE,
      primaryText: 'Go Back',
      primaryAction: this.handleEmptyErrorJSON,
      secondaryText: 'Close',
      secondaryAction: this.handleEmptyErrorJSON,
      renderPostAPIDialog: true
    }

    this.setState({ errorJSON })
    return
  }

  handleAddFunds = async () => {
    const {
      actions,
      authenticateSSOSelector,
      handleError,
      stockDetails,
      subAccountId,
      theme
    } = this.props
    const { refreshToken } = authenticateSSOSelector
    const { token } = refreshToken
    const { segment = '' } = stockDetails || {}
    const requestPayload = {
      type: 'FUND',
      redirectURL: INITIATE_REDIRECTION_LINK,
      refreshToken: token,
      metadata: {
        actionType: 'ADD_FUNDS',
        subAccountId,
        segment,
        theme
      }
    }
    const addFundsResponse = await actions.addFunds(requestPayload)

    if (addFundsResponse._isCustomError) {
      console.log('failed API - add funds initiate')

      return handleError(addFundsResponse)
    }
    const { redirectURL } = addFundsResponse
    window.open(redirectURL, '_self')
  }

  handleEmptyErrorJSON = () => {
    this.setState({
      errorJSON: {
        renderPostAPIDialog: false
      }
    })
  }

  render() {
    const { showGratification, errorJSON } = this.state
    const { renderPostAPIDialog } = errorJSON
    const { formRef, orderData, isHRTAllowedSelect } = this.props

    const {
      quantity = 0,
      price = 0,
      marketDepth = '',
      orderType = '',
      triggerPrice = 0,
      disclosedQty = 0,
      validity = '',
      afterMarketOrder = false,
      sellableQtyType = '',
      encash = false,
      bnpl = false,
      sectionType = 0,
      goodTillDate = ''
    } = orderData || {}

    const initialValues: FormValues = {
      quantity,
      price,
      marketDepth,
      orderType,
      triggerPrice,
      disclosedQty,
      validity,
      afterMarketOrder,
      sellableQtyType,
      encash,
      bnpl,
      sectionType,
      goodTillDate
    }

    return (
      <>
        <Formik
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          validateOnMount={false}
          enableReinitialize
          validationSchema={orderFormSchema(this.props, this.state)}
          innerRef={formRef}
        >
          {this.renderForm}
        </Formik>
        <GratificationDialog
          isHRTAllowedSelect={isHRTAllowedSelect}
          showGratification={showGratification}
          heading={
            sectionType === 1
              ? GRATIFICATION_CASES.GTD_PLACE_ORDER_SUCCESS.heading
              : GRATIFICATION_CASES.PLACE_ORDER_SUCCESS.heading
          }
          subHeading={GRATIFICATION_CASES.GTD_PLACE_ORDER_SUCCESS.subHeading}
        />
        {renderPostAPIDialog && (
          <PostAPIResponseDialog
            {...(renderPostAPIDialog && {
              ...errorJSON
            })}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const orderData = getOrderFormData(state)
  const orderScriptId = getOrderScriptId(state)
  const stockDetails = getStockMasterData(orderScriptId)(state)
  const { holdings } = getHoldingAndAveragePrice(orderScriptId)(state) || {}
  const orderBuyOrSell = getOrderBuyOrSell(state)
  const isModifyOrder = isModifyOrderRequest(state)
  const isRepeatOrder = isRepeatOrderRequest(state)
  const getCurrentModifyOrder = getCurrentModifyOrderResponse(state)
  const isHRTAllowedSelect = isHRTAllowed(state)
  const authenticateSSOSelector = getSSOAuthenticateData(state)
  const { dpId } = getDpIdAndEdisFlagOfCustomer(state)
  const subAccountId = getSubAccountIdSelector(state)
  const postLoginErrorCode = getPostLoginErrorCode(state)
  const isGTDAllowed = isGTDAllowedSelector(state)
  const { scheme } = getThemeReducer(state)

  return {
    orderData,
    orderScriptId,
    stockDetails,
    holdings,
    orderBuyOrSell,
    isModifyOrder,
    isRepeatOrder,
    getCurrentModifyOrder,
    isHRTAllowedSelect,
    authenticateSSOSelector,
    dpId,
    subAccountId,
    postLoginErrorCode,
    isGTDAllowed,
    theme: scheme
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    placeOrder: (data: T_PLACE_ORDER_PAYLOAD) =>
      dispatch(placeOrderAction(data)),
    modifyOrder: (data: T_MODIFY_ORDER_PAYLOAD) =>
      dispatch(modifyOrderAction(data)),
    getOrderBook: (data: T_GET_ORDER_BOOK_PAYLOAD) =>
      dispatch(getOrderBookAction(data)),
    updateOrderPageModalIndicator: (
      requestData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
    ) => dispatch(updateOrderPageModalIndicator(requestData)),
    resetOrderData: (requestData: any) => dispatch(resetOrderData(requestData)),
    authoriseSecurities: (data: any) => dispatch(ssoInitiateAction(data)),
    handleOpenPostLoginError: (requestData: string) =>
      dispatch(handleOpenPostLoginError(requestData)),
    addFunds: (data: any) => dispatch(ssoInitiateAction(data)),
    updateSectionType: (data: number) => dispatch(updateSectionType(data)),
    changeOrderFormData: (payload: { key: string; data: any }) =>
      dispatch(changeOrderFormData(payload)),
    changeOrderbookTab: (orderbookTab: string) =>
      dispatch(changeOrderbookTab(orderbookTab))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(OrderFormSection)
