import React from 'react'
import {
  DsBox,
  DsButtonBase,
  DsHeader,
  DsImage
} from '@am92/react-design-system'

// components
import HeaderRightMenuAction from './HeaderRightMenuAction'
import HeaderTabs from './HeaderTabs'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'

// constants
import { HEADER_ASSETS } from '~/src/Constants/ASSET_MAP'
import StockScrollingHeader from '../StockScrollingHeader/StockScrollingHeader'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'

type ActionTypes = {}
export interface IHeaderProps extends IWithRouterProps {
  actions: ActionTypes
  handleError: (data: any) => void
}

class Header extends React.Component<IHeaderProps> {
  render() {
    const { navigateTo } = this.props
    return (
      <>
        <DsHeader
          sx={{
            display: 'flex',
            position: 'absolute',
            height: {
              md: 'calc(var(--ds-spacing-superheated) + var(--ds-spacing-quickFreeze))'
            }
          }}
          logo={
            <>
              <DsButtonBase
                onClick={() => navigateTo(APP_ROUTES.DASHBOARD.pathname)}
              >
                <DsImage
                  srcSet={HEADER_ASSETS}
                  style={{
                    width: '100vw'
                  }}
                />
              </DsButtonBase>
              <StockScrollingHeader />
            </>
          }
        />
        <DsBox
          sx={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            top: 'calc(var(--ds-spacing-warm) - var(--ds-spacing-deepFreeze))',
            px: 'var(--ds-spacing-bitterCold)'
          }}
        >
          <HeaderTabs />
          <HeaderRightMenuAction />
        </DsBox>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {}
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps)(Header)
