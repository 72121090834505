import * as yup from 'yup'
import {
  IOrderFormSectionProps,
  TOrderFormSectionState
} from './OrderFormSection'
import TickerStore from '~/src/Configurations/TickerStore'
function accurateModulus(dividend: number, divisor: number) {
  let precision = 1e10 // Scale factor to handle floating-point precision
  let scaledDividend = Math.round(dividend * precision)
  let scaledDivisor = Math.round(divisor * precision)
  let scaledModulus = scaledDividend % scaledDivisor
  return scaledModulus / precision
}
const orderFormSchema = (
  props: IOrderFormSectionProps,
  states: TOrderFormSectionState
) => {
  const store = TickerStore.getState()
  return yup.object().shape({
    quantity: yup
      .number()
      .required('This cannot be empty')
      .test({
        test(value: any, ctx) {
          const { stockDetails, orderBuyOrSell, holdings } = props
          const { parent } = ctx
          const { orderType } = parent
          const { maxSingleOrderQty, segment, lotsize } = stockDetails || {}
          if (
            segment === 'EQ' &&
            value &&
            maxSingleOrderQty &&
            value > maxSingleOrderQty &&
            orderBuyOrSell === 'BUY'
          ) {
            return ctx.createError({
              message: `Enter a quantity less than or equal to ${maxSingleOrderQty.toLocaleString('en-IN')}`
            })
          }
          if (
            segment !== 'EQ' &&
            value &&
            lotsize &&
            maxSingleOrderQty &&
            value * lotsize > maxSingleOrderQty &&
            orderBuyOrSell === 'BUY'
          ) {
            return ctx.createError({
              message: `Enter a lot size less than or equal to ${
                maxSingleOrderQty / lotsize
              }`
            })
          }
          if (
            value &&
            value > holdings &&
            orderBuyOrSell === 'SELL' &&
            orderType === 'DELIVERY'
          ) {
            return ctx.createError({
              message: `Enter less than the available holdings`
            })
          }
          if (value === 0 || value < 0) {
            return ctx.createError({
              message: `Please enter the correct ${
                segment !== 'EQ' ? 'lot size' : 'quantity'
              }`
            })
          }
          return true
        }
      }),
    price: yup.number().test({
      test(value: any, ctx) {
        const { priceBoxFocused } = states
        const { ticker } = store
        const { orderScriptId, stockDetails } = props
        const { ticksize } = stockDetails || {}
        const { parent } = ctx
        const { marketDepth, sectionType } = parent
        const scriptTickerData = ticker[orderScriptId]
        const { DAILY_PRICE_RANGE = '' } = scriptTickerData || {}
        const priceRange = DAILY_PRICE_RANGE?.split('-')
        if (marketDepth !== 'market' && value === undefined) {
          return ctx.createError({
            message: 'This cannot be empty'
          })
        }
        if (
          sectionType === 0 &&
          // this check is to make sure that for GTD this validation does not work. sectionType =0 means normal order form
          (priceRange[1] < value || value < priceRange[0]) &&
          marketDepth !== 'market' &&
          priceBoxFocused
        ) {
          return ctx.createError({
            message: 'Price should be within DPR'
          })
        }
        if (
          value % 1 !== 0 &&
          accurateModulus(value, ticksize) !== 0 &&
          marketDepth !== 'market' &&
          priceBoxFocused
        ) {
          return ctx.createError({
            message: 'Price should be multiple of tick price'
          })
        }
        return true
      }
    }),
    triggerPrice: yup.number().test({
      test(value: any, ctx) {
        const { ticker } = store
        const { orderScriptId, stockDetails, orderBuyOrSell } = props
        const { ticksize } = stockDetails || {}
        const scriptTickerData = ticker[orderScriptId]
        const { LTP, DAILY_PRICE_RANGE = '' } = scriptTickerData || {}
        const [min, max] = DAILY_PRICE_RANGE.split('-')
        const { parent } = ctx
        const { price, marketDepth, validity } = parent
        if (
          value &&
          value % 1 !== 0 &&
          accurateModulus(value, ticksize) !== 0
        ) {
          return ctx.createError({
            message: 'Price should be multiple of tick price'
          })
        }
        if (validity !== 'IOC' && (max < value || value < min)) {
          return ctx.createError({
            message: 'Price should be within DPR'
          })
        }
        if (LTP && value && orderBuyOrSell === 'BUY') {
          if (value < Number(LTP)) {
            return ctx.createError({
              message: 'Enter Price higher than LTP'
            })
          }
          if (marketDepth === 'limit' && value > price) {
            return ctx.createError({
              message: 'Enter Price lower than Limit Price'
            })
          }
        }
        if (LTP && value && orderBuyOrSell === 'SELL') {
          if (value > Number(LTP)) {
            return ctx.createError({
              message: 'Enter Price lower than LTP'
            })
          }
          if (marketDepth === 'limit' && value < price) {
            return ctx.createError({
              message: 'Enter Price higher than Limit Price'
            })
          }
        }
        return true
      }
    }),
    disclosedQty: yup.number().test({
      test(value: any, ctx) {
        const { stockDetails } = props
        const { segment } = stockDetails || {}
        const { parent } = ctx
        const { quantity } = parent
        if (value && value > quantity) {
          return ctx.createError({
            message: `Enter a quantity less than or equal to ${quantity}`
          })
        }
        if (segment === 'COMM') {
          if (value && quantity && quantity * 0.25 > value) {
            return ctx.createError({
              message: 'Enter more than 25% of actual quantity'
            })
          }
        }
        if (value && quantity && quantity * 0.1 > value) {
          return ctx.createError({
            message: 'Enter more than 10% of actual quantity'
          })
        }
        return true
      }
    })
  })
}
export default orderFormSchema
