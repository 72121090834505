import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './selectors'
import {
  getMultiplePortfolioActions,
  getMutualFundPortfolioActions
} from './actions'
import localforage from 'localforage'
import { DEFAULT_VALUES } from '../Dashboard/Reducer'
import { MUTUAL_FUND_FILTER_BY_MAPPER } from '~/src/Pages/Portfolio/Components/MutualFund/MutualFund.constants'
import { sortBy } from 'lodash'

export type TMultiplePortfolioSummary = {
  totalInvestedAmount: number
  totalMarketValue: number
  totalUnrealizedGain: number
  totalUnrealizedGainPerc: number
  totalDayGain: number
  totalDayGainPerc: number
}

export type TSinglePortfolioData = {
  clientCode: string
  subAccountName: string
  holdingType: string
  investment: number
  marketValue: number
  unrealizedGain: number
  unrealizedGainPerc: number
  dayGain: number
  dayGainPerc: number
  prevCloseValue: number
  asOnDate: string
}

export interface IMutualFundPortfolioSummary
  extends TMultiplePortfolioSummary {}

export interface ISingleMutualFundPortfolioData
  extends Omit<TSinglePortfolioData, 'clientCode' | 'subAccountName'> {
  mutualFundName: string
  holdingQuantity: string
  averagePrice: string
  livePrice: string
}

export interface IMultiplePortfolioData {
  portfolioSummary: TMultiplePortfolioSummary | null
  portfolio: TSinglePortfolioData[] | null
}

export interface IMutualFundPortfolioData {
  portfolioSummary: IMutualFundPortfolioSummary | null
  portfolio: ISingleMutualFundPortfolioData[] | null
}

export type TMultiplePortFolioSection = {
  title: string
  filter: string
  filterType: boolean
  gridTemplateColumns: string
}

export type TMutualFundSortAndFilter = {
  mutualFundFilter: ISingleMutualFundPortfolioData[] | []
  sortBy: string
}

export interface IPortfolioInitialState {
  multiplePortfolioData: IMultiplePortfolioData
  mutualFundPortfolioData: IMutualFundPortfolioData
  multiplePortFolioSections: TMultiplePortFolioSection[] | null
  selectedPortfolio: TSinglePortfolioData | null
  showMutualFundDisclaimer: boolean
  showMutualFundSortAndFilter: boolean
  mutualFundSortAndFilter: TMutualFundSortAndFilter
  selectedSortAndFilterOption: string
  mutualFundSortedAndFilteredData: ISingleMutualFundPortfolioData[] | null
  isDefaultFiltersEnabled: boolean
}

export const multiPortfolioMapper: {
  [key: string]: string
} = {
  'Direct Transactions-Equity': 'stocks',
  'SmartEdge-Equity': 'smartedgeplus',
  'Managed Account-Beta-Equity': 'cat3',
  'Direct Transactions-MutualFund': 'MutualFund'
}

const INITIAL_STATE: IPortfolioInitialState = {
  multiplePortfolioData: {
    portfolioSummary: null,
    portfolio: null
  },
  mutualFundPortfolioData: {
    portfolioSummary: null,
    portfolio: null
  },
  multiplePortFolioSections: null,
  selectedPortfolio: null,
  showMutualFundDisclaimer: false,
  showMutualFundSortAndFilter: false,
  mutualFundSortAndFilter: {
    mutualFundFilter: [],
    sortBy: 'INVESTED_HIGH_TO_LOW'
  },
  selectedSortAndFilterOption: MUTUAL_FUND_FILTER_BY_MAPPER.SORT_BY.label,
  mutualFundSortedAndFilteredData: null,
  isDefaultFiltersEnabled: true
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedPortfolio: (state, action) => {
      state.selectedPortfolio = action?.payload
    },
    toggleMutualFundDisclaimer: (state, action) => {
      state.showMutualFundDisclaimer = !state.showMutualFundDisclaimer
    },
    toggleMutualFundSortAndFilter: (state, action) => {
      state.showMutualFundSortAndFilter = !state.showMutualFundSortAndFilter
    },
    mutualFundSortAndFilter: (state, action) => {
      state.mutualFundSortAndFilter = action?.payload
      if (checkIfDefaultFiltersAreSet(state)) {
        state.isDefaultFiltersEnabled = true
      } else {
        state.isDefaultFiltersEnabled = false
      }
    },
    changeSelectedSortAndFilterOption: (state, action) => {
      state.selectedSortAndFilterOption = action?.payload
    },
    resetOriginalMutualFundData: state => {
      if (!state.isDefaultFiltersEnabled) {
        state.mutualFundSortedAndFilteredData =
          state.mutualFundPortfolioData.portfolio.sort(
            (
              ITEM_A: ISingleMutualFundPortfolioData,
              ITEM_B: ISingleMutualFundPortfolioData
            ) => {
              const { investment: investedValueA } = ITEM_A
              const { investment: investedValueB } = ITEM_B
              return investedValueB - investedValueA
            }
          )
        state.mutualFundSortAndFilter = INITIAL_STATE.mutualFundSortAndFilter
        state.isDefaultFiltersEnabled = true
      }
      state.selectedSortAndFilterOption =
        INITIAL_STATE.selectedSortAndFilterOption
    },
    setSortedAndFilteredMutualFundData: (state, action) => {
      state.mutualFundSortedAndFilteredData = action?.payload
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getMultiplePortfolioActions.success,
      (state, { payload }) => {
        const { porfolio: portfolio, portfolioSummary } = payload
        const multiplePortFolioSections = []
        let stockPortFolioCount = 0
        let mutualFundPortFolioCount = 0
        for (let i = 0; i < portfolio?.length; i++) {
          const { holdingType } = portfolio[i]
          if (holdingType === 'Equity') {
            stockPortFolioCount++
            continue
          }
          mutualFundPortFolioCount++
        }

        // create portfolio sections
        stockPortFolioCount &&
          multiplePortFolioSections.push({
            title: 'Stocks Portfolios',
            filter: 'MutualFund',
            filterType: false,
            gridTemplateColumns: 'auto auto'
          })

        mutualFundPortFolioCount &&
          multiplePortFolioSections.push({
            title: 'Mutual Funds',
            filter: 'MutualFund',
            filterType: true,
            gridTemplateColumns: '50%'
          })

        state.multiplePortFolioSections = multiplePortFolioSections
        state.multiplePortfolioData = {
          portfolioSummary,
          portfolio
        }
      }
    )
    builder.addCase(
      getMutualFundPortfolioActions.success,
      (state, { payload }) => {
        const { portfolio } = payload
        state.mutualFundPortfolioData = payload
        const mutualFundSortedAndFilteredData = portfolio.sort(
          (
            ITEM_A: ISingleMutualFundPortfolioData,
            ITEM_B: ISingleMutualFundPortfolioData
          ) => {
            const { investment: investedValueA } = ITEM_A
            const { investment: investedValueB } = ITEM_B
            return investedValueB - investedValueA
          }
        )
        state.mutualFundSortedAndFilteredData = mutualFundSortedAndFilteredData

        // initially setting the original data in this temperory source used for sorting and filtering
      }
    )
  }
}

function checkIfDefaultFiltersAreSet(state: IPortfolioInitialState) {
  const { mutualFundSortAndFilter } = state
  const { mutualFundFilter, sortBy } = mutualFundSortAndFilter
  const noFilters = mutualFundFilter.length === 0 ? true : false
  if (sortBy === DEFAULT_VALUES.selectedSortBy && noFilters) {
    return true
  }
  return false
}

const slice = createSlice(sliceOptions)

export const {
  setSelectedPortfolio,
  toggleMutualFundDisclaimer,
  toggleMutualFundSortAndFilter,
  mutualFundSortAndFilter,
  changeSelectedSortAndFilterOption,
  resetOriginalMutualFundData,
  setSortedAndFilteredMutualFundData
} = slice.actions

export const portfolioPersistConfig = {
  key: SLICE_NAME,
  version: 1,
  storage: localforage,
  whitelist: ['selectedPortfolio']
}

export default slice.reducer
