import {
  DsStack,
  DsRemixIcon,
  DsTypography,
  DsBox,
  DsButton
} from '@am92/react-design-system'
import React, { Component } from 'react'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { updateOrderSipPageModalIndicator } from '~/src/Redux/Indicators/Reducer'
import { getOrderPageSipModalIndicator } from '~/src/Redux/Indicators/Selectors'
import SipDiscardConfirmation from './SipDiscardConfirmation'
import { clearSipInfo } from '~/src/Redux/Orders/reducer'

type ActionTypes = {
  updateSipOpenState: (data: any) => any
  updateSipInfo: (data: any) => any
  updateSipNameIndicator: (data: any) => any
}

export interface ISipModalProps {
  actions: ActionTypes
  orderPageSipModalIndicator: boolean
  type?: string
  handleModifySip?: () => Promise<any>
}

export class SipHeader extends Component<ISipModalProps> {
  state = {
    open: false
  }

  setOpen = (e: boolean) => {
    this.setState({ open: e })
  }

  handleDiscard = () => {
    const { actions } = this.props
    this.setState({ open: false })
    actions.updateSipInfo({})
    actions.updateSipOpenState(false)
  }

  handleCloseSipForm = () => {
    this.setOpen(true)
  }

  handleClick = () => {
    const { type = '', handleModifySip } = this.props

    if (type === 'Add Stock') {
      this.setOpen(true)
    } else if (type === 'Review') {
      handleModifySip()
    } else {
      this.handleCloseSipForm()
    }
  }

  render() {
    const { type = '', handleModifySip } = this.props
    const headerHeading = type ? type : 'SIP Order'

    const { open } = this.state

    const formattedIcon = type ? 'ri-arrow-left-line' : 'ri-close-fill'

    return (
      <>
        <DsStack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding:
              'var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold) var(--ds-spacing-mild) var(--ds-spacing-bitterCold)',
            width: '100%'
          }}
        >
          <DsBox
            sx={{
              display: 'flex',
              gap: 'var(--ds-spacing-frostbite)',
              alignItems: 'center'
            }}
          >
            <DsRemixIcon
              onClick={this.handleClick}
              className={formattedIcon}
              fontSize='mild'
              sx={{
                cursor: 'pointer'
              }}
            />
            <DsTypography variant='headingBoldSmall'>
              {headerHeading}
            </DsTypography>
          </DsBox>
          {type === 'Review' && (
            <DsButton
              onClick={handleModifySip}
              startIcon={
                <DsRemixIcon
                  className='ri-pencil-line'
                  color='secondary'
                  fontSize='small'
                />
              }
              variant='text'
              color='secondary'
            >
              Modify
            </DsButton>
          )}
        </DsStack>
        <SipDiscardConfirmation
          open={open}
          setOpen={this.setOpen}
          handleDiscard={this.handleDiscard}
        />
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const orderPageSipModalIndicator = getOrderPageSipModalIndicator(state)
  return {
    orderPageSipModalIndicator
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    updateSipOpenState: (requestData: any) =>
      dispatch(updateOrderSipPageModalIndicator(requestData)),
    updateSipInfo: (requestData: any) => dispatch(clearSipInfo(requestData))
  }
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps)(SipHeader)
