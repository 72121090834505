import { DsBox, DsStack, DsTypography } from '@am92/react-design-system'
import React, { Component } from 'react'
import withErrorConnect from '~/src/Lib/withErrorConnect'

import { updateSelectedScriptsData } from '~/src/Redux/Orders/reducer'
import { getTickerData } from '~/src/Redux/StockTicker/Selectors'
import { tickerContext } from '~/src/Configurations/TickerStore'
import { getCurrencyFormat } from '~/src/Utils/global'
import QuotesStockData from '~/src/Pages/Quotes/Components/QuotesStockData'

export interface ActionType {
  updateSelectedScriptsData: (item: any) => any
}

export interface ISipScriptsDetailsLeftProps {
  actions: ActionType
  item: any
  sipInfo: any
  stockPriceDetails: any
}
export class SipScriptsDetailsLeft extends Component<ISipScriptsDetailsLeftProps> {
  render() {
    const { item, sipInfo, stockPriceDetails = {} } = this.props

    const { basedOn } = sipInfo

    const { LTP = 0, CLOSE_PRICE } = stockPriceDetails

    const formattedValue = LTP === '0.00' || LTP === '0' ? CLOSE_PRICE : LTP

    const normalizedBasedOn = basedOn.toLowerCase()
    const finalValueLabel =
      normalizedBasedOn === 'amount' ? 'Quantity' : 'Amount'

    const finalValue =
      normalizedBasedOn === 'amount'
        ? getCurrencyFormat(
            Math.floor(Number(item.amount) / Number(formattedValue)),
            { style: 'decimal' }
          )
        : getCurrencyFormat(
            Math.floor(Number(formattedValue) * Number(item.quantity)),
            { style: 'decimal' }
          ) || 0

    return (
      <DsStack gap={'8px'} alignItems={'flex-start'}>
        <DsBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1
          }}
        >
          <DsTypography variant='bodyRegularMedium'>{item.coname}</DsTypography>
          <DsTypography sx={{ color: '#6e6e6e' }} variant='bodyRegularSmall'>
            {finalValueLabel}:{finalValue}
          </DsTypography>
        </DsBox>
        <DsBox sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <DsTypography variant='supportRegularInfo'>
            {item.exchange}
          </DsTypography>

          <QuotesStockData
            scriptId={item.scriptId}
            type='quotes'
            ltpTypoVariant='bodyRegularSmall'
            netChangeTypoVariant='supportRegularInfo'
          />
        </DsBox>
      </DsStack>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { item } = ownProps
  const stockPriceDetails = getTickerData(item.scriptId)(state)
  return {
    stockPriceDetails
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    updateSelectedScriptsData: (requestData: any) =>
      dispatch(updateSelectedScriptsData(requestData))
  }
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: tickerContext
})(SipScriptsDetailsLeft)
